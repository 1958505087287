var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.enrolledPatientDictionary)?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('portal',{attrs:{"to":"appBarContentLeft"}},[_c('v-toolbar-items',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('org-switcher'),_c('v-select',{staticClass:"ml-4",staticStyle:{"font-weight":"bold","max-width":"200px","color":"blue"},attrs:{"hide-details":"","solo":"","flat":"","item-text":"date","items":_vm.twelveMonthSet,"return-object":"","dense":"","background-color":"grey lighten-3"},on:{"change":_vm.runReportForSelected},model:{value:(_vm.selectedDateSet),callback:function ($$v) {_vm.selectedDateSet=$$v},expression:"selectedDateSet"}})],1)],1)],1),_c('portal',{attrs:{"to":"appBarContentRight"}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.generateMonthEndSummary(_vm.monthEndSummaryData)}}},[_vm._v("Summary PDF")]),_c('v-btn',{staticClass:"mr-4",attrs:{"depressed":""},on:{"click":function($event){return _vm.createSpreadsheet()}}},[_vm._v("Billing XLSX")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.getExcelFile()}}},[_vm._v("Deluxe XLSX")])],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.runningReport),expression:"runningReport"}],attrs:{"indeterminate":""}}),_c('v-data-table',{staticClass:"row-pointer grey lighten-4",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","fixed-header":"","height":"calc(100vh - 64px)","headers":_vm.headersBilling,"hide-default-footer":"","items":_vm.billingByPatient,"items-per-page":500,"item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[(item.revenue)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert")])],1)]}},{key:"item.rpm.enrollment.start",fn:function(ref){
var item = ref.item;
return [(item.rpm.enrollment.enrolled)?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_vm._v(" "+_vm._s(_vm.translateTheDate(item.rpm.enrollment.start))+" ")]):_vm._e()]}},{key:"item.deviceNum",fn:function(ref){
var item = ref.item;
return [(_vm.devicesByPatient[item.id])?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_vm._v(" "+_vm._s(_vm.devicesByPatient[item.id].length)+" ")]):_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('span',{staticClass:"font-weight-regular text-body-2"},[_vm._v(" - ")])])]}},{key:"item.patientID",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"60px"}},[_vm._v(" "+_vm._s(item.patientID)+" ")])])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.firstName.charAt(0))+". "+_vm._s(item.lastName)+" ")])])]}},{key:"item.sex",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"60px"}},[_vm._v(" "+_vm._s(item.sex.charAt(0))+" ")])])]}},{key:"item.conditions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"7vw"}},[_vm._v(" "+_vm._s(item.conditions)+" ")])])]}},{key:"item.justification",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","small":""}},[_vm._v("mdi-check-circle")]),_vm._v(_vm._s(item.justification)+" ")],1)])]}},{key:"item.devices",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.devices)+" ")])])]}},{key:"item.enrolled",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.enrolled)+" ")])])]}},{key:"item.cpt99454_2",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.cpt99454_2)+" ")])])]}},{key:"item.cpt99454",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.cpt99454)+" ")])])]}},{key:"item.cpt99457",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.cpt99457)+" ")])])]}}],null,false,1487025424)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }