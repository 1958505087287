<template>
  <v-container v-if="enrolledPatientDictionary" fluid class="pa-0">
    <portal to="appBarContentLeft">
      <v-toolbar-items>
        <v-row no-gutters align="center">
          <org-switcher />

          <v-select
            class="ml-4"
            v-model="selectedDateSet"
            style="font-weight: bold; max-width: 200px; color: blue"
            hide-details
            solo
            flat
            item-text="date"
            :items="twelveMonthSet"
            @change="runReportForSelected"
            return-object
            dense
            background-color="grey lighten-3"
          >
          </v-select>
        </v-row>
      </v-toolbar-items>
    </portal>
    <portal to="appBarContentRight">
      <v-row class="flex-nowrap" align="center" justify="end" no-gutters>
        <v-btn class="mr-4" depressed @click="generateMonthEndSummary(monthEndSummaryData)">Summary PDF</v-btn>
        <v-btn class="mr-4" depressed @click="createSpreadsheet()">Billing XLSX</v-btn>
        <v-btn depressed @click="getExcelFile()">Deluxe XLSX</v-btn>
      </v-row>
    </portal>
    <!-- <v-row v-for="(device, index) in orgDevices" :key="index" no-gutters>
      {{ index }}{{ device.kind }}{{ device.deviceRefId }}{{ device.live }}{{ device.patientId }}
    </v-row> -->
    <v-progress-linear indeterminate v-show="runningReport" />
    <v-data-table
      dense
      fixed-header
      height="calc(100vh - 64px)"
      :headers="headersBilling"
      hide-default-footer
      :items="billingByPatient"
      :items-per-page="500"
      class="row-pointer grey lighten-4"
      style="background-color: transparent"
      :item-class="row_classes"
    >
      <!-- <template v-slot:item.lastName="{ item }">
            <v-row justify="start" no-gutters>
              <span class="font-weight-regular text-body-2">
                {{ item.firstName }}
                <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                <span class="text--secondary ml-1" v-if="item.org.patientId">{{ item.org.patientId }}</span>
              </span>
            </v-row>
          </template> -->

      <template v-slot:item.revenue="{ item }">
        <v-row no-gutters align="center" justify="center">
          <v-icon small color="green" v-if="item.revenue">mdi-check-circle</v-icon>
          <v-icon small v-else>mdi-alert</v-icon>
        </v-row>
      </template>

      <template v-slot:item.rpm.enrollment.start="{ item }">
        <v-row v-if="item.rpm.enrollment.enrolled" justify="center" no-gutters>
          {{ translateTheDate(item.rpm.enrollment.start) }}
        </v-row>
      </template>
      <template v-slot:item.deviceNum="{ item }">
        <v-row v-if="devicesByPatient[item.id]" justify="center" no-gutters>
          {{ devicesByPatient[item.id].length }}
        </v-row>
        <v-row v-else justify="center" no-gutters>
          <span class="font-weight-regular text-body-2">
            -
            <!-- {{ enrolledPatientDictionary[item.patientId].firstName.charAt(0) }} -->
            <!-- <span class="font-weight-bold text-body-2">{{ item.lastName }}</span>
                <span class="text--secondary ml-1" v-if="item.org.patientId">{{ item.org.patientId }}</span> -->
          </span>
        </v-row>
      </template>

      <template v-slot:item.patientID="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 60px">
            {{ item.patientID }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.lastName="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate"> {{ item.firstName.charAt(0) }}. {{ item.lastName }} </span>
        </v-row>
      </template>

      <template v-slot:item.sex="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 60px">
            {{ item.sex.charAt(0) }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.conditions="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 7vw">
            {{ item.conditions }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.justification="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate">
            <v-icon class="mr-1" color="green" small>mdi-check-circle</v-icon>{{ item.justification }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.devices="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 100px">
            {{ item.devices }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.enrolled="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 100px">
            {{ item.enrolled }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.cpt99454_2="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 100px">
            {{ item.cpt99454_2 }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.cpt99454="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 100px">
            {{ item.cpt99454 }}
          </span>
        </v-row>
      </template>

      <template v-slot:item.cpt99457="{ item }">
        <v-row justify="start" no-gutters>
          <span class="text-truncate" style="max-width: 100px">
            {{ item.cpt99457 }}
          </span>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import { createActivityCSV, createActivitySpreadsheet } from '@/helpers/csv'
import { state as orgState, getBillingReport, getBillingXLSX } from '@/shared-ui/store/org'
import consts from '@/consts.js'
import OrgSwitcher from '@/components/appdrawer/OrgSwitcher'
import { generateMonthEndSummary } from '@/shared-ui/helpers/pdf'

export default {
  components: { OrgSwitcher },
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
      selectedStart: null,
      selectedEnd: null,
      twelveMonthSet: [],
      selectedDateSet: null,
      allEnrolled: true,
      showSummary: false,
    }
  },
  org: {
    handler: function () {
      console.log('reload the activity report because the org just changed')
    },
    deep: true,
    immediate: true,
  },
  computed: {
    ...mapState('rpm', ['orgDevices']),
    ...mapState('auth', ['user']),
    ...mapState('org', ['org', 'enrolledPatients', 'patientsDict', 'unenrolledPatients']),
    monthEndSummaryData() {
      let dataObject = {}
      dataObject.organization = this.org.name
      dataObject.reportRange = this.selectedDateSet.date
      dataObject.totalEnrolled = this.billingByPatient.length
      dataObject.newPatients = this.newPatientsThisPeriod.length
      // of the patients that have been active for more than 30 days.... what percentage got a 99454?
      dataObject.patientEngagement =
        this.billingByPatient.filter(e => e.cpt99454 !== null).length / this.notNewPatients.length

      // of all of the patients,

      dataObject.sixteenReadings = this.notNewPatients.filter(e => e.cpt99454 !== null).length

      dataObject.tenToFifteenReadings = this.notNewPatients.filter(
        e => e.cpt99454Days > 9 && e.cpt99454Days < 16
      ).length

      dataObject.oneToNineReadings = this.notNewPatients.filter(e => e.cpt99454Days > 0 && e.cpt99454Days < 10).length

      dataObject.zeroReadings = this.notNewPatients.filter(e => e.cpt99454Days === null || e.cpt99454Days === 0).length

      dataObject.noData = this.notNewPatients.filter(e => e.noData).length

      dataObject.qhpEngagement =
        this.billingByPatient.filter(e => e.cpt99457 !== null).length / this.billingByPatient.length

      dataObject.qhp20Mins = this.billingByPatient.filter(e => e.cpt99457 !== null).length
      dataObject.qhp20Plus = this.billingByPatient.filter(e => e.cpt99458 !== null).length
      dataObject.qhpMiss = this.billingByPatient.filter(e => e.cpt99457 === null).length
      dataObject.fourFiveEights = this.raw99458s
      return dataObject
    },
    newPatientsThisPeriod() {
      if (this.selectedDateSet) {
        console.log(Date.parse(this.selectedDateSet.start))
        let thirtyDays = new Date(Date.parse(this.selectedDateSet.start))
        thirtyDays.setHours(0, 0, 0, 0)

        return this.billingByPatient.filter(function (e) {
          let enrollment = new Date(e.enrolled).getTime()
          return enrollment > thirtyDays.getTime()
        })
      }
      return []
    },
    notNewPatients() {
      if (this.selectedDateSet) {
        console.log(Date.parse(this.selectedDateSet.start))
        let thirtyDays = new Date(Date.parse(this.selectedDateSet.start))
        thirtyDays.setHours(0, 0, 0, 0)

        return this.billingByPatient.filter(function (e) {
          if (e.enrolled) {
            let enrollment = new Date(e.enrolled).getTime()
            return thirtyDays.getTime() > enrollment
          }
        })
      }
      return []
    },
    runningReport() {
      return orgState.runningReport
    },
    adjustedEnrolledPatients() {
      // these are the patients who were enrolled before the end of the period

      if (this.selectedDateSet) {
        let endOfPeriod = new Date(Date.parse(this.selectedDateSet.end))
        endOfPeriod.setHours(23, 59, 59, 999)

        return this.enrolledPatients.filter(function (e) {
          let enrollment = new Date(e.rpm.enrollment.start).getTime()
          return enrollment < endOfPeriod.getTime()
        })
      }
      return this.enrolledPatients
    },

    normalizedTotalPatientCountForPeriod() {
      return this.adjustedEnrolledPatients.length - this.newPatientsThisPeriod.length
    },
    billableEvents() {
      let count = 0

      this.billingByPatient.forEach(patient => {
        if (patient.cptCodes && patient.cptCodes.length > 0) {
          count = count + patient.cptCodes.length
        }
      })

      return count
    },
    patientsThatWereBillable() {
      let bllbl =
        (this.globalReportIncludingAllPatients.filter(function (e) {
          return e.revenue
        }).length /
          (this.enrolledPatients.length - this.newInThisPeriod)) *
        100
      return {
        percentage: bllbl.toFixed(1) + '%',
        total: this.globalReportIncludingAllPatients.filter(function (e) {
          return e.revenue
        }).length,
      }
    },
    raw99453s() {
      return this.billingByPatient.filter(function (e) {
        return e.cpt99453 !== '-'
      }).length
    },
    raw99454s() {
      return (
        this.billingByPatient.filter(function (e) {
          return e.cpt99454 !== '-'
        }).length +
        this.billingByPatient.filter(function (e) {
          return e.cpt99454_2 !== '-'
        }).length
      )
    },
    raw99457s() {
      return this.billingByPatient.filter(function (e) {
        return e.cpt99457 !== '-'
      }).length
    },
    noQHPTime() {
      return this.globalReportIncludingAllPatients.filter(function (e) {
        return e.cpt99457 === '-'
      }).length
    },
    raw99458s() {
      return (
        this.billingByPatient.filter(function (e) {
          return e.cpt99458 !== null
        }).length +
        this.billingByPatient.filter(function (e) {
          return e.cpt99458_2 !== null
        }).length +
        this.billingByPatient.filter(function (e) {
          return e.cpt99458_3 !== null
        }).length
      )
    },
    patients99454() {
      let fourfivefours =
        (this.patientsWhoGotA99454 / (this.adjustedEnrolledPatients.length - this.newPatientsThisPeriod.le)) * 100
      return {
        percentage: fourfivefours.toFixed(1) + '%',
        total: this.patientsWhoGotA99454,
      }
    },
    patients99457() {
      let ninesevens = (this.patientsWhoGotA99457 / (this.adjustedEnrolledPatients.length - this.newInThisPeriod)) * 100
      return {
        percentage: ninesevens.toFixed(1) + '%',
        total: this.patientsWhoGotA99457,
      }
    },
    patients99458() {
      return this.billingByPatient.filter(function (e) {
        return e.cpt99458 !== null
      }).length
    },
    newInThisPeriod() {
      let countToReturn = 0
      if (this.selectedDateSet) {
        console.log(Date.parse(this.selectedDateSet.start))
        let thirtyDays = new Date(Date.parse(this.selectedDateSet.start))
        thirtyDays.setHours(0, 0, 0, 0)

        countToReturn = this.enrolledPatients.filter(function (e) {
          let enrollment = new Date(e.rpm.enrollment.start).getTime()
          return enrollment > thirtyDays.getTime()
        }).length
      }
      return countToReturn
    },
    patientsWhoGotA99454() {
      return this.billingByPatient.filter(function (e) {
        return e.cpt99454_1 !== null && e.cpt99454.cpt99454_1 !== ''
      }).length
    },
    patientsWhoGotA99457() {
      return this.billingByPatient.filter(function (e) {
        return e.cpt99457 !== '-'
      }).length
    },
    summaryDetail() {
      let engaged = 300 / 1000
      let tenToFifteenReadings = 0
      let oneToNine = 0
      let zero = 0
      let never = 0

      this.patientReports.forEach(report => {
        let totalReadings = 0
        if (report.qualifications99454.length > 0) {
          if (report.periods.length > 0) {
            report.periods.forEach(period => {
              totalReadings = totalReadings + period.daysWithData.length
            })
          }
        }
        if (totalReadings > 9 && totalReadings < 16) {
          tenToFifteenReadings = tenToFifteenReadings + 1
        }

        if (totalReadings > 0 && totalReadings < 10) {
          oneToNine = oneToNine + 1
        }
      })

      // catch all by subtracting from the total
      zero = this.adjustedEnrolledPatients.length - (tenToFifteenReadings + oneToNine + this.patientsWhoGotA99454)

      // now subtract the nevers?

      never = this.adjustedEnrolledPatients.filter(function (e) {
        return e.noData
      }).length

      zero = zero - never

      return {
        totalEnrolled: this.adjustedEnrolledPatients.length,
        engaged: engaged,
        newPatients: this.newInThisPeriod,
        full99454:
          ((this.patientsWhoGotA99454 / (this.adjustedEnrolledPatients.length - this.newInThisPeriod)) * 100).toFixed(
            2
          ) + '%',
        sixteenReadings: this.patientsWhoGotA99454,
        tenToFifteenReadings: tenToFifteenReadings,
        oneToNineReadings: oneToNine,
        zeroReadings: zero,
        never: never,
        full99457: ((this.patientsWhoGotA99457 / this.adjustedEnrolledPatients.length) * 100).toFixed(2) + '%',
      }
    },
    headersBilling() {
      let headers = [
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'revenue',
          width: 10,
        },
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'patientID',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'lastName',
        },
        {
          text: 'Sex',
          align: 'start',
          sortable: true,
          value: 'sex',
        },
        {
          text: 'Birthdate',
          align: 'start',
          sortable: true,
          value: 'birthdate',
        },

        {
          text: 'Enrollment',
          align: 'start',
          sortable: true,
          value: 'enrolled',
        },
        // {
        //   text: 'Device',
        //   align: 'start',
        //   sortable: true,
        //   value: 'devices',
        // },
        {
          text: 'First Reading',
          align: 'start',
          sortable: true,
          value: 'firstReading',
        },

        {
          text: 'CPT 99453',
          align: 'start',
          sortable: true,
          value: 'cpt99453',
        },

        {
          text: 'CPT 99454 (1)',
          align: 'start',
          sortable: true,
          value: 'cpt99454',
        },
        {
          text: 'Days',
          align: 'start',
          sortable: true,
          value: 'cpt99454Days',
        },
        {
          text: 'CPT 99454 (2)',
          align: 'start',
          sortable: true,
          value: 'cpt99454_2',
        },
        {
          text: 'Days',
          align: 'start',
          sortable: true,
          value: 'cpt99454_2Days',
        },
        {
          text: 'CPT 99457',
          align: 'start',
          sortable: true,
          value: 'cpt99457',
        },
        {
          text: 'CPT 99458 (1)',
          align: 'start',
          sortable: true,
          value: 'cpt99458',
        },
        {
          text: 'CPT 99458 (2)',
          align: 'start',
          sortable: true,
          value: 'cpt99458_2',
        },
        {
          text: 'CPT 99458 (3)',
          align: 'start',
          sortable: true,
          value: 'cpt99458_3',
        },
        {
          text: 'ICD-10',
          align: 'start',
          sortable: true,
          value: 'conditions',
        },
      ]
      return headers
    },
    headers() {
      let headers = [
        {
          text: 'Patient ID',
          align: 'start',
          sortable: true,
          value: 'patientID',
        },
        {
          text: 'Patient Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'ICD-10',
          align: 'start',
          sortable: true,
          value: 'conditions',
        },
        {
          text: 'Monitoring Device',
          align: 'start',
          sortable: true,
          value: 'devices',
        },
        {
          text: 'Suggested Code',
          align: 'start',
          sortable: true,
          value: 'cpt',
        },
        {
          text: 'Code Requirement',
          align: 'start',
          sortable: true,
          value: 'req',
        },
        {
          text: 'Justification',
          align: 'start',
          sortable: true,
          value: 'justification',
        },
        {
          text: 'Billable Date',
          align: 'center',
          sortable: true,
          value: 'billable',
        },
      ]
      return headers
    },
    devicesByPatient() {
      let dict = {}
      this.orgDevices.forEach(device => {
        if (device.patientId) {
          if (dict[device.patientId]) {
            dict[device.patientId].push(device)
          } else {
            dict[device.patientId] = []
            dict[device.patientId].push(device)
          }
        }
      })
      return dict
    },
    invoiceableDevices() {
      let dict = {}
      this.orgDevices.forEach(device => {
        if (device.patientId) {
          if (dict[device.patientId]) {
            dict[device.patientId].push(device)
          } else {
            dict[device.patientId] = []
            dict[device.patientId].push(device)
          }
        }
      })
      return dict
    },
    totalEnrolledPatients() {
      return this.enrolledPatients.length
    },
    globalReportIncludingAllPatients() {
      return this.billingByPatient
      // this should return all enrolled patients. even the ones without anything...
      // let combinedList = []
      // this.adjustedEnrolledPatients.forEach(patient => {
      //   let patientRow = this.billingByPatient.find(patientHere => patientHere.arrowID === patient.id)
      //   if (patientRow) {
      //     patientRow.patientID = patient.org.patientId
      //     patientRow.firstName = patient.firstName
      //     patientRow.lastName = patient.lastName
      //     patientRow.sex = patient.gender.charAt(0)
      //     patientRow.birthdate = patient.birthdate.substr(0, 10)
      //     patientRow.enrolled = '-'
      //     patientRow.firstReading = '-'
      //     if (patient.rpm.enrollment.start) {
      //       patientRow.enrolled = patient.rpm.enrollment.start.substr(0, 10)
      //     }
      //     if (patient.rpm.enrollment.firstData) {
      //       patientRow.firstReading = patient.rpm.enrollment.firstData.substr(0, 10)
      //     }

      //     // decide if this patient should be invoicable?
      //     // if enrollment occured before the start of the startdate?

      //     if (this.selectedDateSet) {
      //       patientRow.invoiceable = false
      //       let startOfPeriod = new Date(Date.parse(this.selectedDateSet.start))
      //       startOfPeriod.setHours(0, 0, 0, 0)

      //       if (patient.rpm.enrollment.start) {
      //         let enrolledDate = new Date(patient.rpm.enrollment.start).getTime()
      //         if (enrolledDate < startOfPeriod.getTime()) {
      //           patientRow.invoiceable = true
      //         }
      //       }
      //     }

      //     combinedList.push(patientRow)
      //   } else {
      //     patientRow = {}
      //     patientRow.revenue = false
      //     patientRow.patientID = patient.org.patientId
      //     patientRow.firstName = patient.firstName
      //     patientRow.lastName = patient.lastName
      //     patientRow.sex = patient.gender.charAt(0)
      //     patientRow.birthdate = patient.birthdate.substr(0, 10)
      //     patientRow.enrolled = '-'
      //     patientRow.firstReading = '-'
      //     if (patient.rpm.enrollment.start) {
      //       patientRow.enrolled = patient.rpm.enrollment.start.substr(0, 10)
      //     }
      //     if (patient.rpm.enrollment.firstData) {
      //       patientRow.firstReading = patient.rpm.enrollment.firstData.substr(0, 10)
      //     }

      //     if (this.selectedDateSet) {
      //       patientRow.invoiceable = false
      //       let startOfPeriod = new Date(Date.parse(this.selectedDateSet.start))
      //       startOfPeriod.setHours(0, 0, 0, 0)

      //       if (patient.rpm.enrollment.start) {
      //         let enrolledDate = new Date(patient.rpm.enrollment.start).getTime()
      //         if (enrolledDate < startOfPeriod.getTime()) {
      //           patientRow.invoiceable = true
      //         }
      //       }
      //     }

      //     patientRow.devices = null

      //     if (this.devicesByPatient[patient.id]) {
      //       this.devicesByPatient[patient.id].forEach(device => {
      //         let name = device.kind
      //         if (consts.DEVICES[device.kind] && device.live) {
      //           name = consts.DEVICES[device.kind].name
      //         }
      //         if (patientRow.devices === null) {
      //           patientRow.devices = name
      //         } else {
      //           patientRow.devices = patientRow.devices + ' | ' + name
      //         }
      //       })
      //     } else {
      //       patientRow.devices = '-'
      //     }

      //     patientRow.firstReading = '-'

      //     if (patient.rpm.enrollment.firstData) {
      //       patientRow.firstReading = patient.rpm.enrollment.firstData.substr(0, 10)
      //     }
      //     patientRow.cpt99453 = '-'
      //     patientRow.cpt99454 = '-'
      //     patientRow.cpt99454Days = '-'
      //     patientRow.cpt99454_2 = '-'
      //     patientRow.cpt99454_2Days = '-'
      //     patientRow.cpt99457 = '-'
      //     patientRow.cpt99458 = '-'
      //     patientRow.cpt99458_2 = '-'
      //     patientRow.cpt99458_3 = '-'

      //     let conditions = ''

      //     if (patient.conditions.length > 0) {
      //       patient.conditions.forEach(condition => {
      //         let icd10 = condition.split('|')[0]
      //         if (conditions) {
      //           conditions = conditions + ' | ' + icd10
      //         } else {
      //           conditions = icd10
      //         }
      //       })
      //     }

      //     patientRow.conditions = conditions

      //     combinedList.push(patientRow)
      //   }
      // })
      // return combinedList
    },
    billingByPatient() {
      let patientArray = []
      this.patientReports.forEach(report => {
        let patientRow = {}

        patientRow.patientID = report.patient.id

        patientRow.arrowID = report.patient.id

        if (report.patient.ownerPatientId) {
          patientRow.patientID = report.patient.ownerPatientId
        }

        if (this.enrolledPatientDictionary[report.patient.id]) {
          patientRow.patientID = this.enrolledPatientDictionary[report.patient.id].org.patientId
        }

        patientRow.firstName = report.patient.firstName
        patientRow.lastName = report.patient.lastName
        patientRow.sex = report.patient.gender
        patientRow.birthdate = report.patient.birthdate.substr(0, 10)

        if (this.selectedDateSet) {
          patientRow.invoiceable = false
          let startOfPeriod = new Date(Date.parse(this.selectedDateSet.start))
          startOfPeriod.setHours(0, 0, 0, 0)

          if (report.patient.rpm.enrollment.start) {
            let enrolledDate = new Date(report.patient.rpm.enrollment.start).getTime()
            if (enrolledDate < startOfPeriod.getTime()) {
              patientRow.invoiceable = true
            }
          }
        }

        if (
          this.enrolledPatientDictionary[report.patient.id] &&
          this.enrolledPatientDictionary[report.patient.id].rpm &&
          this.enrolledPatientDictionary[report.patient.id].rpm.activity &&
          this.enrolledPatientDictionary[report.patient.id].rpm.activity.dates
        ) {
          patientRow.cpt99454Days = this.enrolledPatientDictionary[report.patient.id].rpm.activity.dates.filter(e =>
            e.includes(this.selectedDateSet.end.substr(0, 7))
          ).length
        }

        if (
          this.enrolledPatientDictionary[report.patient.id] &&
          this.enrolledPatientDictionary[report.patient.id].noData
        ) {
          patientRow.noData = true
        }

        if (report.patient.rpm.enrollment.start) {
          patientRow.enrolled = report.patient.rpm.enrollment.start.substr(0, 10)
        } else {
          patientRow.enrolled = null
        }

        if (report.patient.rpm.enrollment.firstData) {
          patientRow.firstReading = report.patient.rpm.enrollment.firstData.substr(0, 10)
        }

        patientRow.conditions = null

        if (report.patient.conditions.length > 0) {
          report.patient.conditions.forEach(condition => {
            let icd10 = condition.split('|')[0]
            if (patientRow.conditions) {
              patientRow.conditions = patientRow.conditions + ', ' + icd10
            } else {
              patientRow.conditions = icd10
            }
          })
        }

        patientRow.devices = null

        if (this.devicesByPatient[report.patient.id]) {
          this.devicesByPatient[report.patient.id].forEach(device => {
            let name = device.kind
            if (consts.DEVICES[device.kind] && device.live) {
              name = consts.DEVICES[device.kind].name
            }
            if (patientRow.devices === null) {
              patientRow.devices = name
            } else {
              patientRow.devices = patientRow.devices + ' | ' + name
            }
          })
        } else {
          patientRow.devices = '-'
        }

        // // should you inclide 99453?
        patientRow.cptCodes = []
        if (report.qualification99453 && report.qualification99453 != '') {
          patientRow.cptCodes.push({
            cpt: 'CPT 99453',
            req: 'Initial setup and patient education on equipment use',
            justification: 'Initial Enrollment',
            billable: report.qualification99453,
          })
          if (report.periods[0]) {
            patientRow.cpt99453 = report.periods[0].start
          }
        }

        // // how about the 99457s? always include two?
        patientRow.cpt99454 = null
        if (report.qualifications99454.length > 0) {
          if (report.periods.length > 0) {
            let added = false
            report.periods.forEach(period => {
              if (period.daysWithData.length >= 16) {
                patientRow.cptCodes.push({
                  cpt: 'CPT 99454',
                  req: '16 days of readings in a 30 day period',
                  justification: period.daysWithData.length + ' Days',
                  billable: period.end,
                })
                if (added) {
                  patientRow.cpt99454_2 = period.end
                  patientRow.cpt99454_2Days = period.daysWithData.length
                } else {
                  patientRow.cpt99454 = period.end
                  patientRow.cpt99454Days = period.daysWithData.length
                }
                added = true
              } else {
                if (added) {
                  patientRow.cpt99454_2Days = period.daysWithData.length
                } else {
                  patientRow.cpt99454Days = period.daysWithData.length
                }
                added = true
              }
            })
          }
        }

        patientRow.cpt99457 = null

        if (report.qualifications99457.length > 0) {
          patientRow.cptCodes.push({
            cpt: 'CPT 99457',
            req: '20 minutes of monitoring',
            justification: 'Completed',
            billable: this.selectedDateSet.end,
          })
          patientRow.cpt99457 = this.selectedDateSet.end
        }

        patientRow.cpt99458 = null
        patientRow.cpt99458_2 = null
        patientRow.cpt99458_3 = null

        if (report.qualifications99458.length > 0) {
          let count = 0
          report.qualifications99458.forEach(() => {
            if (count < 3) {
              patientRow.cptCodes.push({
                cpt: 'CPT 99458',
                req: 'Additional 20 minutes of monitoring',
                justification: 'Completed',
                billable: this.selectedDateSet.end,
              })
              if (count === 0) {
                patientRow.cpt99458 = this.selectedDateSet.end
              }
              if (count === 1) {
                patientRow.cpt99458_2 = this.selectedDateSet.end
              }
              if (count === 2) {
                patientRow.cpt99458_3 = this.selectedDateSet.end
              }
              count = count + 1
            }
          })
        }
        if (patientRow.cptCodes.length > 0) {
          patientRow.revenue = true
          patientArray.push(patientRow)
        } else {
          patientRow.revenue = false
          patientArray.push(patientRow)
        }
      })
      return patientArray
    },
    cptList() {
      let patientArray = []
      let color = false
      this.patientReports.forEach(report => {
        // add a line item for each cpt code. up to three 99458s
        let patientID = report.patient.id

        if (this.enrolledPatientDictionary[report.patient.id]) {
          patientID = this.enrolledPatientDictionary[report.patient.id].org.patientId
        }

        let devices = null

        if (this.devicesByPatient[report.patient.id]) {
          this.devicesByPatient[report.patient.id].forEach(device => {
            let name = device.kind
            if (consts.DEVICES[device.kind] && device.live) {
              name = consts.DEVICES[device.kind].name
            }
            if (devices === null) {
              devices = name
            } else {
              devices = devices + ' | ' + name
            }
          })
        }
        let name = report.patient.firstName.charAt(0) + '. ' + report.patient.lastName
        let conditions = null

        if (report.patient.conditions.length > 0) {
          report.patient.conditions.forEach(condition => {
            let icd10 = condition.split('|')[0]
            if (conditions) {
              conditions = conditions + ' | ' + icd10
            } else {
              conditions = icd10
            }
          })
        }

        let added = false

        // is there a 99453? was the patient enrolled this month?
        if (report.qualifications99453 && report.qualifications99453 != '') {
          console.log('99453: ' + report.qualifications99453)
          patientArray.push({
            patientID: patientID,
            name: name,
            devices: devices,
            cpt: 'CPT 99453',
            req: 'Initial setup and patient education on equipment use',
            justification: 'Initial Enrollment',
            billable: report.qualifications99453,
            color: color,
            conditions: conditions,
          })
          added = true
        }

        if (report.qualifications99454.length > 0) {
          if (report.periods.length > 0) {
            report.periods.forEach(period => {
              if (period.daysWithData.length >= 16) {
                patientArray.push({
                  patientID: patientID,
                  name: name,
                  devices: devices,
                  cpt: 'CPT 99454',
                  req: '16 days of readings in a 30 day period',
                  justification: period.daysWithData.length + ' Days',
                  billable: period.end,
                  color: color,
                  conditions: conditions,
                })
                added = true
              }
            })
          }
        }

        if (report.qualifications99457.length > 0) {
          patientArray.push({
            patientID: patientID,
            name: name,
            devices: devices,
            cpt: 'CPT 99457',
            req: '20 minutes of monitoring',
            justification: 'Completed',
            billable: this.selectedDateSet.end,
            color: color,
            conditions: conditions,
          })
          added = true
        }

        if (report.qualifications99458.length > 0) {
          let count = 0
          report.qualifications99458.forEach(() => {
            if (count < 3) {
              patientArray.push({
                patientID: patientID,
                name: name,
                devices: devices,
                cpt: 'CPT 99458',
                req: 'Additional 20 minutes of monitoring',
                justification: 'Completed',
                billable: this.selectedDateSet.end,
                color: color,
                conditions: conditions,
              })
              added = true
              count = count + 1
            }
          })
        }
        if (added) {
          color = !color
        }
      })
      return patientArray
    },
    combinedPatientList() {
      return this.enrolledPatients.concat(this.unenrolledPatients)
    },
    isViewingUnassigned() {
      return true
    },
    enrolledPatientDictionary() {
      let dict = {}

      this.enrolledPatients.forEach(patient => {
        dict[patient.id] = patient
      })
      return dict
    },
    orgDevicesCorrelatedWithEnrolledPatients() {
      let rawDevices = this.orgDevices

      let toReturn = []

      rawDevices.forEach(device => {
        let dvToReturn = {}

        dvToReturn.kind = device.kind
        dvToReturn.deviceID = device.deviceRefId
        dvToReturn.age = 0

        if (
          device.live &&
          this.enrolledPatientDictionary[device.patientId] &&
          device.orgAssignedTs === '1970-01-01T00:00:00Z'
        ) {
          dvToReturn.dateAssigned = dayjs(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start)
          ).format('MM/DD/YYYY')
          const date1 = dayjs()
          dvToReturn.age = date1.diff(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start),
            'month'
          )
        } else if (device.orgAssignedTs) {
          const date1 = dayjs()
          dvToReturn.age = date1.diff(Date.parse(device.orgAssignedTs), 'month')
          dvToReturn.dateAssigned = dayjs(Date.parse(device.orgAssignedTs)).format('MM/DD/YYYY')
        }
        if (dvToReturn.age > 0 && dvToReturn.age < 14) {
          toReturn.push(dvToReturn)
        }
      })
      return toReturn
    },
    patientReports() {
      return orgState.patientReports
    },
  },
  methods: {
    generateMonthEndSummary,
    ...mapActions('rpm', [
      'assignDevice',
      'getPatientIHealthOauthFlow',
      'getPatient',
      'getOrgDevices',
      'getPatientDevices',
    ]),
    ...mapActions('org', ['getBillingReport']),
    row_classes(item) {
      if (item.revenue) {
        return 'white' //can also return multiple classes e.g ["orange","disabled"]
      } else {
        return 'grey lighten-4'
      }
    },
    translateTheDate(date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    translateTheMonth(date) {
      return dayjs(date).format('MMM YYYY')
    },
    createSpreadsheet() {
      createActivitySpreadsheet(
        this.billingByPatient,
        this.org.name + ' ' + this.selectedDateSet.date + ' Activity Report ',
        this.orgDevicesCorrelatedWithEnrolledPatients
      )
    },
    exportTheList() {
      createActivityCSV(
        this.globalReportIncludingAllPatients,
        this.org.name + ' ' + this.selectedDateSet.date + ' Activity Report '
      )
    },
    cardClick(cat) {
      this.selectedCategory = cat
    },
    graphClick() {},
    async runReportForSelected() {
      await getBillingReport(this.selectedDateSet.start, this.selectedDateSet.end, this.org.id)
    },
    async getExcelFile() {
      await getBillingXLSX({
        month: this.selectedDateSet.start.substr(0, 7),
        orgId: this.org.id,
        orgName: this.org.name,
      })
    },
  },

  async mounted() {
    await this.getOrgDevices(this.org.id)
    // start of last month, and and of last month

    // set the months

    const date = new Date()

    const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    // 👇️ Tue Feb 01 2022 00:00:00
    console.log(firstDayPrevMonth)

    const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0)
    // 👇️ Mon Feb 28 2022 00:00:00
    console.log(lastDayPrevMonth.toISOString())

    this.selectedStart = firstDayPrevMonth.toISOString().substr(0, 10)

    this.selectedEnd = lastDayPrevMonth.toISOString().substr(0, 10)

    // go back 12 months?

    for (let i = 1; i < 12; i++) {
      let start = new Date(date.getFullYear(), date.getMonth() - i, 1)
      let end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
      this.twelveMonthSet.push({
        start: start.toISOString().substr(0, 10),
        end: end.toISOString().substr(0, 10),
        date: this.translateTheMonth(start),
      })
    }
    this.selectedDateSet = this.twelveMonthSet[0]

    await this.runReportForSelected()
  },
}
</script>

<style lang="scss" scoped>
.progress-circular-underlay-stroke {
  color: blue;
}
.inactiveClass {
  opacity: 0.6;
}
.shaded {
  background-color: #e7f8ec;
}
</style>
