<template>
  <v-card flat color="transparent" class="flex">
    <v-row class="py-4" no-gutters align="center" justify="center">
      <span class="font-weight-bold">{{ chartTitle }}</span>
    </v-row>
    <v-card height="400" color="transparent" flat>
      <canvas :id="uniqueID"></canvas>
    </v-card>

    <v-card v-if="false" class="flex px-2" tile flat>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr v-for="(row, index) in filterCategory" :key="index">
              <td align="left">
                <v-row no-gutters>
                  <span class="text-caption">{{ row.name }} </span>
                </v-row>
              </td>
              <td align="right">
                <v-row justify="end" class="pr-1" style="min-width: 100px">
                  <span class="font-weight-bold mr-1">{{ data[index] }}</span>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api'
import Chart from 'chart.js'
import 'chartjs-adapter-date-fns'

export default defineComponent({
  props: {
    population: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
      default: 'totalEnrolled',
    },
    filterCategory: {
      type: Array,
      default: () => [],
    },
    orgDict: {
      type: Object,
      default: () => {},
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    patientSortKey: {
      type: String,
      default: null,
    },
    chartTitle: {
      type: String,
      default: 'Title',
    },
  },
  setup(props, { emit }) {
    const chart = ref(null)
    const uniqueID = ref(Math.floor(Math.random() * 100000) + ':Identifier')

    watch(
      () => props.population,
      () => {
        if (props.population.length > 0) {
          loadChart1()
        }
      },
      { deep: true }
    )

    const data = computed(() => {
      let actualData = []
      props.population.forEach(org => {
        if (props.percentage) {
          actualData.push(((org.stats[props.category] / org.stats.totalEnrolled) * 100).toFixed(0))
        } else {
          actualData.push(org.stats[props.category])
        }
      })

      return actualData
    })

    // const enrolledButNotNew = computed(() => {
    //   let actualData = []
    //   props.population.forEach(org => {
    //     actualData.push(org.stats.totalEnrolled - org.stats.addedInLast30Days)
    //   })

    //   return actualData
    // })

    const newPatients = computed(() => {
      let actualData = []
      props.population.forEach(org => {
        actualData.push(org.stats.addedInLast30Days)
      })

      return actualData
    })

    const activeLast48 = computed(() => {
      let actualData = []
      props.population.forEach(org => {
        actualData.push(org.stats.activeLast48)
      })

      return actualData
    })

    const qhpTotals = computed(() => {
      let actualData = []
      props.population.forEach(org => {
        actualData.push(org.stats.qualifiedFor99457)
      })

      return actualData
    })

    const labels = computed(() => {
      let actualData = []
      props.population.forEach(org => {
        actualData.push(props.orgDict[org.id].name)
      })

      return actualData
    })

    const dataToAdd = computed(() => {
      return {
        labels: labels.value,
        datasets: [
          {
            label: 'Enrolled Patients',
            data: data.value,
            backgroundColor: '#4485ed',
            stack: 'Stack 0',
          },
          {
            label: 'New',
            data: newPatients.value,
            backgroundColor: 'green',
            stack: 'Stack 1',
          },
          {
            label: 'Active',
            data: activeLast48.value,
            backgroundColor: 'orange',
            stack: 'Stack 2',
          },
          {
            label: '99457',
            data: qhpTotals.value,
            backgroundColor: 'blue',
            stack: 'Stack 3',
          },
        ],
      }
    })

    const loadChart1 = () => {
      if (chart.value) {
        chart.value.destroy()
      }
      const ctx = document.getElementById(uniqueID.value)
      chart.value = new Chart(ctx, {
        type: 'bar',

        data: dataToAdd.value,
        options: {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  display: false,
                },
                stacked: true,
              },
            ],
          },
          legend: {
            display: true,
          },
          onClick: function (e) {
            const activePoints = chart.value.getElementsAtEventForMode(
              e,
              'nearest',
              {
                intersect: true,
              },
              false
            )

            engagementFilter(activePoints[0]._index)
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: true,

            custom: function (tooltipModel) {
              if (tooltipModel.opacity === 1) {
                chart.value.canvas.style.cursor = 'pointer'
              } else {
                chart.value.canvas.style.cursor = null
              }
            },
          },

          responsive: true,
          maintainAspectRatio: false,
        },
      })
    }

    const engagementFilter = index => {
      emit('engagementFilter', index)
    }

    onMounted(() => {
      loadChart1()
    })

    return { uniqueID, data }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
