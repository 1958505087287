import { jsPDF } from 'jspdf'
import dayjs from 'dayjs'
import { getPatientEvents } from '../store/rpm'

export const exportFacesheet = async (patient, start) => {
  let startDate = start
  let endDate = dayjs() // now

  console.log(patient)

  let metricList = Object.keys(patient.latestEvents)
  let metricOrder = [
    { displayName: 'BloodPressure', kind: 'MEASUREMENT_BLOOD_PRESSURE', abbrev: 'BP' },
    { displayName: 'Pulse', kind: 'MEASUREMENT_PULSE', abbrev: 'HR' },
    { displayName: 'BloodGlucose', kind: 'MEASUREMENT_BLOOD_GLUCOSE', abbrev: 'GLU' },
    { displayName: 'BodyWeight', kind: 'MEASUREMENT_BODY_WEIGHT', abbrev: 'WT' },
  ]
  // this should also set the order. BP goes first, then HR, then glucose, then weight.
  let displayedMetrics = []
  metricOrder.forEach(metric => {
    if (metricList.includes(metric.kind)) {
      displayedMetrics.push({
        name: metric.kind,
        displayName: metric.displayName,
        abbrev: metric.abbrev,
      })
    }
  })

  // this function creates the table headers, and calculates the column widths specific to the metrics this particular patient has.
  function createHeaders(keys) {
    var headers = []
    keys.forEach((key, idx) => {
      let standardWidth = 80
      let w = standardWidth
      if (idx == 0) {
        // width - 50 * number of items
        w = 735 - standardWidth * displayedMetrics.length
      }
      let headerTitle = 'Date'
      let metric = metricOrder.find(m => m.displayName === key)
      if (metric) {
        headerTitle = metric.abbrev
      }

      headers.push({
        id: key,
        name: key,
        prompt: headerTitle,
        width: w,
        align: 'left',
        padding: 10,
      })
    })
    return headers
  }

  // this section fetches the latest metrics within the date range for each of the metrics that are available for this patient
  let mainDict = {}

  await Promise.all(
    displayedMetrics.map(async metric => {
      try {
        let events = await getPatientEvents(patient.id, metric.name, startDate, endDate)
        if (events && events.length) {
          // there is data
          events.forEach(event => {
            let value = '-'
            if (event.data?.diastolicValue) {
              value = `${event.data.systolicValue}/${event.data.diastolicValue}`
            } else {
              value = event.data.value.toString()
            }
            if (mainDict[event.timestamp]) {
              mainDict[event.timestamp][event.kind] = value
            } else {
              let objectForPlacement = {}
              // even if there is not a value for the metric we need something for the pdf so put a '-'
              displayedMetrics.forEach(metric => {
                objectForPlacement[metric.displayName] = '-'
              })
              objectForPlacement[event.kind] = value
              objectForPlacement.date = dayjs(event.timestamp).format('MM/DD/YYYY HH:mm a')
              mainDict[event.timestamp] = objectForPlacement
            }
          })
        }
      } catch (e) {
        console.log('error getting events', e)
      }
    })
  )
  // when the counter reaches zero, each of the metrics for this patient have been downloaded, we can sort them into a dictionary now

  let keys = Object.keys(mainDict)

  keys.sort(function (a, b) {
    return b - a
  })

  let data = []
  let id = 0
  keys.forEach(key => {
    let obj = mainDict[key]
    obj.id = id.toString()
    data.push(obj)
    id = id + 1
  })

  let headersProto = ['date']

  displayedMetrics.forEach(metric => {
    headersProto.push(metric.displayName)
  })

  const headers = createHeaders(headersProto)
  generateFacesheet(patient, startDate, dayjs(), data, headers)
}

export const generateFacesheet = (patient, startDate, endDate, data, headers) => {
  let imgData = require('@/shared-ui/assets/Arrowhealth.jpg')

  const doc = new jsPDF('p', 'pt', 'letter')

  doc.addImage(imgData, 'JPEG', 410, 24, 166, 24)

  // create patient info header.
  // name: PT Name
  // Phone: PT Phone
  // Address: PT Address
  // width is 735
  // 595 left for the width of the data cells
  // 200 for first data cell

  let patientInfoHeaders = [
    {
      id: 0,
      name: 'Title1',
      prompt: '1',
      width: 80,
      align: 'right',
      padding: 10,
    },
    {
      id: 1,
      name: 'Data1',
      prompt: '1',
      width: 180,
      align: 'left',
      padding: 10,
    },
    {
      id: 2,
      name: 'Title2',
      prompt: '1',
      width: 80,
      align: 'right',
      padding: 10,
    },
    {
      id: 3,
      name: 'Data2',
      prompt: '1',
      width: 395,
      align: 'left',
      padding: 10,
    },
  ]

  // make sure the data actually exists on the patient so it doesnt break, then create the data set for the info table
  //TODO: implement conditions into the pdf

  let infoData = [
    {
      Title1: 'Name:',
      Data1: patient.lastName ? `${patient.firstName} ${patient.lastName}` : 'MISSING NAME',
      Title2: 'Birthdate:',
      Data2: patient.birthdate ? dayjs(patient.birthdate).format('MM/DD/YYYY') : 'MISSING BIRTHDATE',
    },
    {
      Title1: 'Phone:',
      Data1: patient.smsPhone || 'MISSING PHONE',
      Title2: 'Patient ID:',
      Data2: patient.org?.patientId || 'MISSING PID',
    },
    {
      Title1: 'Address:',
      Data1: patient.displayAddress || 'MISSING ADDRESS',
      Title2: 'Diagnosis:',
      Data2:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ]

  doc.table(30, 55, infoData, patientInfoHeaders, {
    autoSize: false,
    fontSize: 10,
  })

  doc.setDrawColor(255, 255, 255)
  doc.setFillColor(255, 255, 255)
  doc.rect(29, 54, 553, 18, 'FD')

  // add a text box for the diagnosis

  // doc.setFont("helvetica")
  // doc.text(patient.firstName + ' '  + patient.lastName, 30, 70);
  // doc.setFontSize(10);
  // doc.text(patient.age + ' ' + patient.shortGender + ' ' + patient.displayBirthdate + ' | PID: ' + patient.ownerPatientID, 30, 83)

  doc.text('Document Created: ' + dayjs().format('MM/DD/YYYY HH:mm a'), 30, 37)
  doc.text(
    'Data Range: ' +
      dayjs(startDate).format('MM/DD/YYYY HH:mm a') +
      ' - ' +
      dayjs(endDate).format('MM/DD/YYYY HH:mm a'),
    30,
    51
  )

  doc.setFontSize(30)
  // doc.text('Remote Patient Monitoring Summary', 30, 50, 'left')
  doc.setFontSize(10)
  // just split the data page 1 can only do 30
  // create the tables based on the length of the data

  // page 1 should adapt to the height for the patient demographic information

  // 18 is the normal cell height.

  doc.setDrawColor(0, 0, 255)
  doc.setFillColor(255, 255, 255)
  doc.rect(285, 107.5, 296.3, 52, 'FD')

  let yVal = 119

  patient.conditions = patient.conditions || []
  patient.conditions.forEach(condition => {
    let textArray = condition.split('|')
    let fullText = textArray[0] + ' - ' + textArray[1]

    if (fullText.length > 61) {
      fullText = fullText.substring(0, 61).trim() + '...'
    }
    doc.text(fullText, 288, yVal)
    yVal = yVal + 11.5
  })

  doc.setDrawColor(0, 0, 0)

  let firstPageCount = 30
  let nextPageCount = 39
  if (data.length < firstPageCount) {
    doc.table(30, 180, data, headers, { autoSize: false, fontSize: 10 })
    doc.text(patient.firstName + ' ' + patient.lastName + ' | Page 1 of 1', 308, 765, 'center')
  } else {
    let tableCount = Math.ceil((data.length - firstPageCount) / nextPageCount + 1)
    // take the first 30 out and put it in the first page
    let firstPage = data.splice(0, firstPageCount)
    doc.table(30, 180, firstPage, headers, { autoSize: false, fontSize: 10 })
    doc.text(patient.firstName + ' ' + patient.lastName + ' | Page 1 of ' + tableCount, 308, 765, 'center')

    // the rest of data can be divided into groups of 42
    for (let i = 1; i < tableCount; i++) {
      doc.addPage()
      let tableSet = data.splice(0, nextPageCount)
      doc.table(30, 40, tableSet, headers, { autoSize: false, fontSize: 10 })
      let page = i + 1
      doc.text(patient.firstName + ' ' + patient.lastName + ' | Page ' + page + ' of ' + tableCount, 308, 765, 'center')
    }
  }

  doc.save(patient.lastName + '_export.pdf')
}

export const generateMonthEndSummary = summaryData => {
  let imgData = require('@/assets/Arrowhealth-bug-RGB.png')

  const doc = new jsPDF('p', 'pt', 'letter')

  console.log(summaryData)

  let generalInfoHeaders = [
    {
      id: 0,
      name: 'label',
      prompt: '',
      width: 650,
      align: 'left',
      padding: 10,
    },
    {
      id: 1,
      name: 'value',
      prompt: '',
      width: 80,
      align: 'right',
      padding: 10,
    },
  ]

  let generalInfoData = [
    {
      label: 'Enrolled Patients',
      value: summaryData.totalEnrolled.toString(),
    },
    {
      label: 'New Patients',
      value: summaryData.newPatients.toString(),
    },
  ]

  let engagementHeaders = [
    {
      id: 0,
      name: 'label',
      prompt: '',
      width: 650,
      align: 'left',
      padding: 10,
    },
    {
      id: 1,
      name: 'value',
      prompt: '',
      width: 80,
      align: 'right',
      padding: 10,
    },
  ]

  let engagementData = [
    {
      label: 'Patient Engagement',
      value: (summaryData.patientEngagement * 100).toFixed(2).toString() + '%',
    },
    { label: '16+ readings [99454]**', value: summaryData.sixteenReadings.toString() },
    { label: '10 - 15 readings', value: summaryData.tenToFifteenReadings.toString() },
    { label: '1 - 9 readings', value: summaryData.oneToNineReadings.toString() },
    { label: '0 readings in this period', value: summaryData.zeroReadings.toString() },
    { label: 'Never taken a reading', value: summaryData.noData.toString() },
  ]

  let qhpHeaders = [
    {
      id: 0,
      name: 'label',
      prompt: 'QHP Performance',
      width: 250,
      align: 'left',
      padding: 10,
    },
    {
      id: 1,
      name: 'value',
      prompt: '',
      width: 80,
      align: 'right',
      padding: 10,
    },
    {
      id: 1,
      name: 'action',
      prompt: '',
      width: 400,
      align: 'right',
      padding: 10,
    },
  ]

  let qhpData = [
    {
      label: 'QHP Engagement',
      value: (summaryData.qhpEngagement * 100).toFixed(2).toString() + '%',
      action: '-',
    },
    { label: '20 minutes QHP time [99457]**', value: summaryData.qhp20Mins.toString(), action: '-' },
    { label: '20+ minutes QHP time [99458]**', value: summaryData.qhp20Plus.toString(), action: '-' },
    { label: 'BELOW 20 minutes QHP time', value: summaryData.qhpMiss.toString(), action: '-' },
    { label: 'Total 99458 occurrences', value: summaryData.fourFiveEights.toString(), action: '-' },
  ]

  doc.setDrawColor(0, 20, 38)
  doc.setFillColor(0, 20, 38)
  doc.rect(0, 0, 640, 120, 'FD')
  doc.addImage(imgData, 'PNG', 530, 24, 42, 35)
  doc.setTextColor('#FFFFFF')
  doc.setFontSize(18)
  doc.setFont('helvetica', 'bold')
  doc.text(summaryData.organization, 30, 105)
  doc.setTextColor('#1298CA')
  doc.setFontSize(14)
  // doc.text(summaryData.reportRange + ' Month End Summary', 30, 40)
  doc.setFontSize(18)
  doc.text(summaryData.reportRange + ' Month End Summary', 30, 78)

  doc.setTextColor('#444444')
  doc.setFontSize(12)
  doc.setFont('helvetica', 'normal')

  doc.setDrawColor('#ffffff')

  doc.cell(
    30,
    145,
    546,
    24,
    'The below summary is a review of your monthly achievements and highlights opportunities to improve patient engagement.'
  )

  doc.setDrawColor('#000000')
  doc.setFillColor('#EEEEEE')

  doc.table(30, 190, generalInfoData, generalInfoHeaders, {
    autoSize: false,
    fontSize: 12,
  })

  doc.table(30, 270, engagementData, engagementHeaders, {
    autoSize: false,
    fontSize: 12,
  })

  doc.table(30, 430, qhpData, qhpHeaders, {
    autoSize: false,
    fontSize: 12,
  })

  // create patient info header.
  // name: PT Name
  // Phone: PT Phone
  // Address: PT Address
  // width is 735
  // 595 left for the width of the data cells
  // 200 for first data cell

  doc.save('new_export.pdf')
}
