<template>
  <v-card flat color="transparent" class="flex py-4">
    <v-row no-gutters justify="center">
      <v-card max-width="200" color="transparent" flat>
        <v-row class="pb-4" no-gutters align="center" justify="center">
          <span class="font-weight-bold">{{ chartTitle }}</span>
        </v-row>
        <canvas :id="uniqueID"></canvas>
      </v-card>
    </v-row>
    <v-card color="transparent" class="flex px-2 pt-6" tile flat>
      <v-simple-table :key="updateKey" style="background-color: transparent" dense>
        <template v-slot:default>
          <tbody>
            <tr
              @click="selectedFilter(index)"
              style="cursor: pointer"
              v-for="(row, index) in filterCategory"
              :key="index"
            >
              <td align="left">
                <v-row no-gutters>
                  <span class="text-caption text-truncate" style="width: 110px"
                    ><v-icon :color="colorPalette.backgroundColor[index]" small class="mr-1">mdi-circle</v-icon
                    >{{ row.name }}
                  </span>
                </v-row>
              </td>
              <td align="right">
                <v-row justify="end" class="pr-1" style="min-width: 40px">
                  <span class="font-weight-bold mr-1">{{ data[index] }}</span>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api'
import Chart from 'chart.js'
import 'chartjs-adapter-date-fns'
import CONSTS from '@/consts'

export default defineComponent({
  props: {
    population: {
      type: Array,
      default: () => [],
    },
    filterCategory: {
      type: Array,
      default: () => [],
    },
    patientSortKey: {
      type: String,
      default: null,
    },
    chartTitle: {
      type: String,
      default: 'Title',
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const chart = ref(null)
    const uniqueID = ref(Math.floor(Math.random() * 100000) + ':Identifier')

    const updateKey = ref(new Date().toUTCString())

    watch(
      () => props.population,
      () => {
        if (props.population.length > 0) {
          loadChart1()
        }
      },
      { deep: true }
    )

    watch(
      () => props.filters,
      () => {
        if (chart.value) {
          chart.value.data.datasets[0].backgroundColor = colorPalette.value.backgroundColor
          chart.value.data.datasets[0].borderWidth = colorPalette.value.borderWidth
          chart.value.update()
          updateKey.value = new Date().toUTCString()
        }
      },
      { deep: true }
    )
    const getSelectedColor = (tag, correctColor) => {
      if (props.filters.includes(tag)) {
        return '#1A76F2'
      }
      return correctColor
    }

    const getSelectedBorderColor = tag => {
      if (props.filters.includes(tag)) {
        return '#1A76F2'
      }
      return '#ffffff'
    }

    const getSelectedWidth = tag => {
      if (props.filters.includes(tag)) {
        return 3
      }
      return 1
    }
    const colorPalette = computed(() => {
      const backgroundColor = []
      const borderWidth = []
      const borderColor = []
      let successMod = 0
      let warningMod = 0
      let failMod = 0
      props.filterCategory.forEach(category => {
        if (category.color === 0) {
          backgroundColor.push(getSelectedColor(category.tag, CONSTS.colorsForGraphs.success[successMod]))
          borderWidth.push(getSelectedWidth(category.tag))
          borderColor.push(getSelectedBorderColor(category.tag))
          successMod = successMod + 1
        }
        if (category.color === 1) {
          backgroundColor.push(getSelectedColor(category.tag, CONSTS.colorsForGraphs.warning[warningMod]))
          borderWidth.push(getSelectedWidth(category.tag))
          borderColor.push(getSelectedBorderColor(category.tag))
          warningMod = warningMod + 1
        }
        if (category.color === 2) {
          backgroundColor.push(getSelectedColor(category.tag, CONSTS.colorsForGraphs.failure[failMod]))
          borderWidth.push(getSelectedWidth(category.tag))
          borderColor.push(getSelectedBorderColor(category.tag))
          failMod = failMod + 1
        }
        if (category.color === 3) {
          backgroundColor.push(getSelectedColor(category.tag, CONSTS.colorsForGraphs.issue[0]))
          borderWidth.push(getSelectedWidth(category.tag))
          borderColor.push(getSelectedBorderColor(category.tag))
        }
        if (category.color === 4) {
          backgroundColor.push(getSelectedColor(category.tag, CONSTS.colorsForGraphs.issue[1]))
          borderWidth.push(getSelectedWidth(category.tag))
          borderColor.push(getSelectedBorderColor(category.tag))
        }
      })
      return { backgroundColor: backgroundColor }
    })

    const data = computed(() => {
      let actualData = []
      props.filterCategory.forEach(cat => {
        let total = 0
        props.population.forEach(org => {
          if (org.stats && org.stats.localStatsForComparison) {
            total = total + org.stats.localStatsForComparison[cat.name]
          }
        })
        actualData.push(total)
      })

      return actualData
    })

    const dataToAdd = computed(() => {
      return {
        labels: props.filterCategory.map(function (e) {
          return e.name
        }),
        datasets: [
          {
            label: 'style',
            data: data.value,
            backgroundColor: colorPalette.value.backgroundColor,
            borderColor: '#ffffff',
            borderWidth: colorPalette.value.borderWidth,
          },
        ],
      }
    })

    const loadChart1 = () => {
      if (chart.value) {
        chart.value.data.datasets[0].data = data.value
        chart.value.update()
      } else {
        const ctx = document.getElementById(uniqueID.value)
        chart.value = new Chart(ctx, {
          type: 'doughnut',

          data: dataToAdd.value,
          options: {
            legend: {
              display: false,
            },
            onClick: function (e) {
              const activePoints = chart.value.getElementsAtEventForMode(
                e,
                'nearest',
                {
                  intersect: true,
                },
                false
              )

              selectedFilter(activePoints[0]._index)
            },
            tooltips: {
              // Disable the on-canvas tooltip
              enabled: true,

              custom: function (tooltipModel) {
                if (tooltipModel.opacity === 1) {
                  chart.value.canvas.style.cursor = 'pointer'
                } else {
                  chart.value.canvas.style.cursor = null
                }
              },
            },

            responsive: true,
            aspectRatio: 1.4,
          },
        })
      }
    }

    const selectedFilter = index => {
      emit('selectedFilter', { category: props.patientSortKey, tag: props.filterCategory[index].tag })
    }

    onMounted(() => {
      loadChart1()
    })

    return { uniqueID, data, colorPalette, selectedFilter, updateKey }
  },
})
</script>

<style></style>
