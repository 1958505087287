<template>
  <v-card flat color="transparent" class="flex py-2 px-3">
    <v-row class="py-4" no-gutters align="center" justify="center">
      <span class="font-weight-bold">{{ chartTitle }}</span>
      <v-btn small style="position: absolute; right: 0px" text @click="combined = !combined"
        ><span v-if="combined">Simple</span> <span v-else>Detailed</span></v-btn
      >
    </v-row>

    <v-card height="300" color="transparent" flat>
      <canvas :id="uniqueID"></canvas>
    </v-card>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api'
import Chart from 'chart.js'
import 'chartjs-adapter-date-fns'

export default defineComponent({
  props: {
    population: {
      type: Array,
      default: () => [],
    },
    chartTitle: {
      type: String,
      default: 'Title',
    },
  },
  setup(props, { emit }) {
    const chart = ref(null)
    const uniqueID = ref(Math.floor(Math.random() * 100000) + ':Identifier')
    const combined = ref(false)

    watch(
      () => props.population,
      () => {
        if (props.population.length > 0) {
          loadChart1()
        }
      },
      { deep: true }
    )

    watch(
      () => combined,
      () => {
        loadChart1()
      },
      { deep: true }
    )

    const dataToAdd = computed(() => {
      let datasets = []
      let runningTotalDict = {}

      props.population.forEach(element => {
        let concentrated = []
        let dataDict = {}
        element.enrollmentData.data.forEach(datapoint => {
          // make this the first of the month
          var date = new Date(datapoint.x),
            y = date.getFullYear(),
            m = date.getMonth() + 1
          var firstDay = new Date(y, m, 1).getTime().toString()

          if (dataDict[firstDay]) {
            dataDict[firstDay] = dataDict[firstDay] + 1
          } else {
            dataDict[firstDay] = 1
          }

          if (runningTotalDict[firstDay]) {
            runningTotalDict[firstDay] = runningTotalDict[firstDay] + 1
          } else {
            runningTotalDict[firstDay] = 1
          }
        })

        let growth = 0
        Object.keys(dataDict).forEach(key => {
          growth = growth + dataDict[key]
          concentrated.push({ x: parseInt(key), y: growth })
        })
        datasets.push({
          label: element.enrollmentData.org,
          data: concentrated,
          backgroundColor: '#4485ed0f',
          borderColor: '#4485ed',
          borderWidth: 1,
        })
      })
      if (combined.value) {
        datasets = []
        let growthTotal = 0
        let totalConcentrated = []
        Object.keys(runningTotalDict)
          .sort((a, b) => a - b)
          .forEach(key => {
            growthTotal = growthTotal + runningTotalDict[key]
            console.log('growth total', growthTotal)
            totalConcentrated.push({ x: parseInt(key), y: growthTotal })
          })

        datasets.push({
          label: 'Population',
          data: totalConcentrated,
          backgroundColor: '#4485ed0f',
          borderColor: '#4485ed',
          borderWidth: 1,
        })
      }

      return {
        datasets: datasets,
      }
    })

    const loadChart1 = () => {
      if (chart.value) {
        chart.value.destroy()
      }
      const ctx = document.getElementById(uniqueID.value)
      chart.value = new Chart(ctx, {
        type: 'line',

        data: dataToAdd.value,
        options: {
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 0,
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'month',
                },
                ticks: {
                  suggestedMin: '2020/01/01',
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                display: true,
                type: 'linear',
                show: true,
                ticks: {
                  maxTicksLimit: 2,
                },
                grid: {
                  drawBorder: false,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
          onClick: function (e) {
            const activePoints = chart.value.getElementsAtEventForMode(
              e,
              'nearest',
              {
                intersect: true,
              },
              false
            )
            console.log(dataToAdd.value.datasets[activePoints[0]._datasetIndex])
            selectedFilter(dataToAdd.value.datasets[activePoints[0]._datasetIndex].data[activePoints[0]._index].x)
          },
          // tooltips: {
          //   // Disable the on-canvas tooltip
          //   enabled: true,

          //   custom: function (tooltipModel) {
          //     if (tooltipModel.opacity === 1) {
          //       chart.value.canvas.style.cursor = 'pointer'
          //     } else {
          //       chart.value.canvas.style.cursor = null
          //     }
          //   },
          // },

          responsive: true,
          maintainAspectRatio: false,
        },
      })
    }

    const selectedFilter = index => {
      emit('selectedFilter', { category: 'weeksCategory', tag: index })
    }

    onMounted(() => {
      loadChart1()
    })

    return { uniqueID, combined }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
