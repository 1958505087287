<template>
  <v-container>
    <portal to="appBarContentLeft">
      <v-row class="flex-nowrap" align="center" justify="space-between" no-gutters>
        <span style="font-weight: bold"> Enterprise Statistics </span>
      </v-row>
    </portal>
    <v-tabs v-model="graphTab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab> Snapshot </v-tab>
      <v-tab> Comparison </v-tab>
      <v-tab> Growth </v-tab>
    </v-tabs>
    <v-tabs-items v-model="graphTab">
      <v-tab-item>
        <v-row class="py-4" justify="space-between" no-gutters>
          <v-col cols="4" class="px-2">
            <enterprise-donut-filter
              patientSortKey="qhpCategory"
              :filterCategory="categoriesForSorting.qhpTimeThisMonth"
              :population="selectedOrganizations"
              chartTitle="Health Coaching"
            />
          </v-col>
          <v-col class="px-2" cols="4">
            <enterprise-donut-filter
              patientSortKey="lastReadingCategory"
              :filterCategory="categoriesForSorting.timeSinceLastReading"
              :population="selectedOrganizations"
              chartTitle="Last Reading"
            />
          </v-col>
          <v-col class="px-2" cols="4">
            <enterprise-donut-filter
              patientSortKey="onTrackCategory"
              :filterCategory="categoriesForSorting.engagementPerfectionNeeded"
              :population="selectedOrganizations"
              chartTitle="16 Day Forecast"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <org-bar-comparison chartTitle="Current Snapshot" :population="selectedOrganizations" :orgDict="fullOrgsDict" />
      </v-tab-item>

      <v-tab-item>
        <pop-growth chartTitle="Population Growth" :population="selectedOrganizations" />
      </v-tab-item>
    </v-tabs-items>

    <v-container>
      <v-row>
        <v-col cols="1" sm="3" md="3" lg="2" xl="1">
          <v-card flat class="flex" height="100">
            <v-row style="font-weight: bold; font-size: 40px; opacity: 0.7" class="pt-3" no-gutters justify="center">
              {{ orgsTotal }}
            </v-row>
            <v-row class="text-caption" no-gutters justify="center"> Organizations </v-row>
            <v-row no-gutters justify="center"> </v-row>
          </v-card>
        </v-col>
        <v-col cols="1" sm="3" md="3" lg="2" xl="1">
          <v-card flat class="flex" height="100">
            <v-row style="font-weight: bold; font-size: 40px; opacity: 0.7" class="pt-3" no-gutters justify="center">
              {{ patientTotal }}
            </v-row>
            <v-row class="text-caption" no-gutters justify="center"> Enrolled Patients </v-row>
            <v-row no-gutters justify="center"> </v-row>
          </v-card>
        </v-col>
        <v-col cols="1" sm="3" md="3" lg="2" xl="1">
          <v-card flat class="flex" height="100">
            <v-row style="font-weight: bold; font-size: 40px; opacity: 0.7" class="pt-3" no-gutters justify="center">
              {{ newTotal }}
            </v-row>
            <v-row class="text-caption" style="text-align: center" no-gutters justify="center">
              New in the last 30 days
            </v-row>
            <v-row no-gutters justify="center"> </v-row>
          </v-card>
        </v-col>
        <v-col cols="1" sm="3" md="3" lg="2" xl="1">
          <v-card flat class="flex" height="100">
            <v-row style="font-weight: bold; font-size: 40px; opacity: 0.7" class="pt-3" no-gutters justify="center">
              {{ activeTotal }}
            </v-row>
            <v-row class="text-caption" style="text-align: center" no-gutters justify="center">
              Active within the last 48 hours
            </v-row>
            <v-row no-gutters justify="center"> </v-row>
          </v-card>
        </v-col>
        <v-col cols="1" sm="3" md="3" lg="2" xl="1">
          <v-card flat class="flex" height="100">
            <v-row style="font-weight: bold; font-size: 40px; opacity: 0.7" class="pt-3" no-gutters justify="center">
              {{ activeInLast15 }}
            </v-row>
            <v-row class="text-caption" style="text-align: center" no-gutters justify="center">
              Active within the last 15 days
            </v-row>
            <v-row no-gutters justify="center"> </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      v-model="selectedOrganizations"
      show-select
      fixed-header
      :headers="headers"
      :items="processedOrgs"
      :items-per-page="50"
      class="row-pointer grey lighten-4"
      style="background-color: transparent"
    >
      <template v-slot:item.updated="{ item }">
        <v-row v-if="item.updated" justify="start" no-gutters>
          {{ dateAndTime(item.updated) }}
        </v-row>
      </template>

      <template v-slot:item.updated="{ item }">
        <v-row v-if="item.updated" justify="start" no-gutters>
          {{ dateAndTime(item.updated) }}
        </v-row>
      </template>
      <template v-slot:item.lastBridgeAccess="{ item }">
        <v-row v-if="item.lastBridgeAccess" justify="start" no-gutters>
          {{ dateAndTime(item.lastBridgeAccess) }}
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { getOrgs } from '@/shared-ui/store/org'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'
import OrgBarComparison from '@/components/summarycharts/OrgBarComparison.vue'
import PopGrowth from '@/components/summarycharts/PopGrowth.vue'
import CONSTS from '@/consts'
import EnterpriseDonutFilter from '@/components/summarycharts/EnterpriseDonutFilter.vue'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      password: 'password',
      changeDetected: false,
      notifyOnPatientAlert: false,
      firstName: null,
      lastName: null,
      statisticsCards: [],
      fullOrgsDict: {},
      selectedOrganizations: [],
      graphTab: 0,
      hiddenOrgs: [
        '5e39227d1dd9b10d0bd42895',
        '5e8656560904f316136fa08e',
        '5fa072aa06ffea7cda2a9374',
        '5fbc3c5606ffea7cdac4e77c',
        '5f7f98df06ffea7cda8fcf94',
        '604a5df5eed81023222ce30a',
        '606602718c650e93e46c98eb',
        '60cbb029c1fda73a1b6f9196',
        '61159375a07a85650c7d00b6',
      ],
    }
  },
  components: { OrgBarComparison, PopGrowth, EnterpriseDonutFilter },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['org']),
    categoriesForSorting() {
      return CONSTS.sortingCategories
    },
    headers() {
      return [
        {
          text: 'Organization',
          value: 'name',
        },
        {
          text: 'Enrolled',
          value: 'totalEnrolled',
        },
        {
          text: 'New',
          value: 'addedInLast30Days',
        },
        {
          text: '%',
          value: 'addedInLast30DaysPercentage',
        },
        {
          text: 'Active',
          value: 'activeLast48',
        },
        {
          text: '%',
          value: 'activeLast48Percentage',
        },
        {
          text: '99457',
          value: 'qualifiedFor99457',
        },
        {
          text: '%',
          value: 'qualifiedFor99457Percentage',
        },
        {
          text: 'Updated',
          value: 'updated',
        },
      ]
    },
    processedOrgs() {
      let orgs = []
      if (this.fullOrgsDict) {
        this.statisticsCards.forEach(org => {
          if (
            org.stats &&
            !this.hiddenOrgs.includes(org.id) &&
            this.fullOrgsDict[org.id] &&
            this.fullOrgsDict[org.id].name
          ) {
            let returnOrg = org
            returnOrg.name = this.fullOrgsDict[org.id].name
            Object.keys(org.stats).forEach(key => {
              // get a percentage for everythign but the e
              returnOrg[key] = org.stats[key]
              if (key !== 'totalEnrolled' && key !== 'updated' && org.stats.totalEnrolled > 0) {
                returnOrg[key + 'Percentage'] = ((org.stats[key] / org.stats.totalEnrolled) * 100).toFixed(0)
              }
            })
            let data = { org: returnOrg.name, data: [] }
            if (org.stats.enrollmentDates) {
              let sorted = JSON.parse(JSON.stringify(org.stats.enrollmentDates)).sort((a, b) => a.enrolled - b.enrolled)
              let start = 0
              sorted.forEach(patient => {
                start = start + 1
                data.data.push({ x: patient.enrolled, y: start })
              })
            }
            returnOrg.enrollmentData = data
            orgs.push(returnOrg)
          }
        })
      }

      return orgs
    },
    orgsTotal() {
      if (this.selectedOrganizations.length > 0) {
        return this.selectedOrganizations.length
      }

      return this.processedOrgs.length
    },
    patientTotal() {
      let totalPts = 0
      if (this.selectedOrganizations.length > 0) {
        this.selectedOrganizations.forEach(org => {
          totalPts = totalPts + org.stats.totalEnrolled
        })
      } else {
        this.processedOrgs.forEach(org => {
          totalPts = totalPts + org.stats.totalEnrolled
        })
      }

      return totalPts
    },
    newTotal() {
      let totalPts = 0
      if (this.selectedOrganizations.length > 0) {
        this.selectedOrganizations.forEach(org => {
          totalPts = totalPts + org.stats.addedInLast30Days
        })
      } else {
        this.processedOrgs.forEach(org => {
          totalPts = totalPts + org.stats.addedInLast30Days
        })
      }

      return totalPts
    },
    activeTotal() {
      let totalPts = 0
      if (this.selectedOrganizations.length > 0) {
        this.selectedOrganizations.forEach(org => {
          totalPts = totalPts + org.stats.activeLast48
        })
      } else {
        this.processedOrgs.forEach(org => {
          totalPts = totalPts + org.stats.activeLast48
        })
      }

      return totalPts
    },
    activeInLast15() {
      let totalPts = 0
      if (this.selectedOrganizations.length > 0) {
        this.selectedOrganizations.forEach(org => {
          if (org.stats.activeLast15) {
            totalPts = totalPts + org.stats.activeLast15
          }
        })
      } else {
        this.processedOrgs.forEach(org => {
          if (org.stats.activeLast15) {
            totalPts = totalPts + org.stats.activeLast15
          }
        })
      }

      return totalPts
    },
    orgsForDisplay() {
      let orgs = []

      this.user.orgs.forEach(org => {
        orgs.push(org.orgId)
      })
      return orgs
    },
    orgDict() {
      let orgs = {}

      this.user.orgs.forEach(org => {
        orgs[org.orgId] = org
      })
      return orgs
    },
    selectedOrgsEnrollmentDates() {
      let enrollments = []
      if (this.selectedOrganizations.length > 0) {
        this.selectedOrganizations.forEach(org => {
          let data = { org: org.orgId, data: [] }
          let monthsDict = {}
          if (org.stats.enrollmentDates) {
            let sorted = JSON.parse(JSON.stringify(org.stats.enrollmentDates)).sort((a, b) => a.enrolled - b.enrolled)

            sorted.forEach(patient => {
              data.data.push({ x: patient.enrolled, y: 1 })
              var date = new Date(patient.enrolled),
                y = date.getFullYear(),
                m = date.getMonth()
              var firstDay = new Date(y, m, 1)

              if (monthsDict[firstDay.getTime().toString()]) {
                monthsDict[firstDay.getTime().toString()] = monthsDict[firstDay.getTime().toString()] + 1
              } else {
                monthsDict[firstDay.getTime().toString()] = 1
              }
            })
          }
          console.log('data before sending', monthsDict)
          enrollments.push(data)
        })
      }
      return enrollments
    },
  },
  watch: {
    processedOrgs() {
      if (this.statisticsCards.filter(e => e.done).length === this.orgsForDisplay.length) {
        console.log('should add')
        let vm = this
        setTimeout(() => (vm.selectedOrganizations = vm.processedOrgs), 500)
      }
    },
  },
  methods: {
    ...mapActions('auth', ['updateUser']),
    ...mapActions('inbox', ['fetchOrgStats']),
    dateAndTime(stamp) {
      return specificRelativeTimeWithHoursAndMinutes(stamp)
    },
    async updateNotifyOnPatientAlert() {
      await this.updateUser({ settings: { notifications: { onPatientAlert: this.notifyOnPatientAlert } } })
      bus.$emit('toast', { type: 'success', text: 'Account Updated' })
    },
    async saveUpdates() {
      if (this.changeDetected) {
        await this.updateUser({ firstName: this.firstName, lastName: this.lastName })
        this.changeDetected = false
        bus.$emit('toast', { type: 'success', text: 'Account Updated' })
      }
    },
  },
  async beforeCreate() {
    const fullOrgsDict = {}
    let orgs = await getOrgs()
    for (const org of orgs) {
      fullOrgsDict[org.id] = org
    }
    this.fullOrgsDict = fullOrgsDict
    console.log('full orgs dict should be good1')
  },
  async mounted() {
    this.statisticsCards = await this.fetchOrgStats(this.orgsForDisplay)
  },
}
</script>
